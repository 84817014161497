import {FC, useEffect, useState} from 'react';

import {Text} from '~/modules/Common/components/Text';
import {XIcon} from '@heroicons/react/outline';

interface GeneralErrorProps {
  error?: string;
  dismissable?: boolean;
  className?: string;
  isWarning?: boolean;
}

export const GeneralError: FC<GeneralErrorProps> = ({
  error,
  dismissable = true,
  className = '',
}) => {
  const [isVisible, setIsVisible] = useState(error && error.length > 0);
  useEffect(() => {
    setIsVisible(error && error.length > 0);
  }, [error]);

  return isVisible && error ? (
    <div
      className={`flex flex-row flex-nowrap items-center gap-2 p-4 text-sm rounded-lg bg-remy700 text-white mb-4 ${className}`}
      role="alert"
    >
      <div>
        <Text className="inline text-left font-bold text-white">{`Error: `}</Text>
        <span>{error}</span>
      </div>
      {dismissable && (
        <button
          onClick={() => setIsVisible(false)}
          aria-label="Dismiss"
          className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 "
          type="button"
        >
          <XIcon />
        </button>
      )}
    </div>
  ) : null;
};
